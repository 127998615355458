<div
  *ngIf="user"
  class="uj-flex uj-flex-col uj-gap-c uj-p-e uj-w-[280px] sm:uj-w-[320px]">
  <div
    class="uj-flex uj-items-center uj-justify-between uj-gap-c tw-cursor-not-allowed">
    <ui-library-avatar
      *ngIf="user.profile.image; else avatarWithoutImage"
      content="image"
      [imageUrl]="user.profile.image | storedDocumentUrl"
      icon="user"
      backgroundColor="white"></ui-library-avatar>
    <ng-template #avatarWithoutImage>
      <ui-library-avatar
        *ngIf="user.profile?.firstName && user.profile?.lastName"
        content="letters"
        [letters]="
          user.profile.firstName.slice(0, 1) + user.profile.lastName.slice(0, 1)
        "
        icon="user"
        backgroundColor="white"></ui-library-avatar>
    </ng-template>
  </div>
  <div class="uj-flex uj-flex-col uj-gap-a">
    <span class="text-m-b"
      >{{ user.profile.firstName }}
      {{ user.profile.lastName }}
      <ng-container *ngIf="isCurrentUser"
        >({{ "iMyself" | i18next }})</ng-container
      ></span
    >
    <span
      class="text-m uj-text-text-grey"
      *ngIf="user.profile.position"
      >{{ user.profile.position }}</span
    >
    <span
      *ngIf="user.isBot"
      class="text-m uj-text-text-grey"
      >{{ "switzerland" | i18next }}</span
    >
    <span
      class="text-m uj-text-text-grey"
      *ngIf="loggedInUserBranchesNames">
      <span *ngFor="let branchName of loggedInUserBranchesNames; let i = index">
        {{ branchName }}
        <ng-container
          *ngIf="
            loggedInUserBranchesNames.length > 1 &&
            i !== loggedInUserBranchesNames.length - 1
          "
          >,</ng-container
        >
      </span>
    </span>
  </div>
  <div class="uj-flex uj-gap-c">
    <ng-container *ngIf="!user.isBot; else isUserBot">
      <a
        *ngIf="user.profile.email as email"
        ui-library-button-outline
        target="_blank"
        [href]="email | teamsLink"
        class="uj-pointer-events-auto"
        iconOnly="teams"
        color="dark">
      </a>
      <a
        *ngIf="user.profile?.phone as phone"
        ui-library-button-outline
        target="_blank"
        [href]="'tel:' + phone"
        class="uj-pointer-events-auto"
        iconOnly="phone"
        color="dark">
      </a>
      <a
        *ngIf="user.profile?.email as email"
        ui-library-button-outline
        target="_blank"
        [href]="'mailto:' + email"
        class="uj-pointer-events-auto"
        iconOnly="mail"
        color="dark">
      </a>
    </ng-container>
    <ng-template #isUserBot>
      <a
        ui-library-button-outline
        target="_blank"
        [href]="userBackLink"
        class="uj-pointer-events-auto"
        iconLeft="edit_pen_2"
        color="dark">
        {{ "feedback" | i18next | firstUppercase }}
      </a>
      <a
        *ngIf="user.profile?.email as email"
        ui-library-button-outline
        target="_blank"
        [href]="'mailto:' + email"
        class="uj-pointer-events-auto"
        iconOnly="mail"
        color="dark">
      </a>
    </ng-template>
  </div>
</div>
