import { Component, Input } from '@angular/core';
import { AnyUser } from '../../../../models/types';

@Component({
  selector: 'app-user-info-context-menu',
  templateUrl: './user-info-context-menu.component.html',
})
export class UserInfoContextMenuComponent {
  @Input({ required: true }) user: AnyUser | undefined;
  @Input() loggedInUserBranchesNames: string[] | undefined;
  @Input() isCurrentUser = false;

  userBackLink = 'https://universaljob.ideas.userback.io';
}
