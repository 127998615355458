import { Observable, pipe, UnaryFunction } from 'rxjs';
import { map } from 'rxjs/operators';

export function rxThrowIfEmpty<T>(
  message = 'Observable is empty',
): UnaryFunction<Observable<T | null | undefined>, Observable<T>> {
  return pipe(
    map((x) => {
      if (x === null || x === undefined) {
        throw new Error(message);
      }
      return x;
    }),
  );
}
