import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedDefaultModule } from '../../shared-default/shared-default.module';
import { UserInfoContextMenuComponent } from './user-info-context-menu.component';
import { ButtonOutlineComponent } from '@intemp/unijob-ui2';

@NgModule({
  declarations: [UserInfoContextMenuComponent],
  imports: [CommonModule, SharedDefaultModule, ButtonOutlineComponent],
  exports: [UserInfoContextMenuComponent],
})
export class UserInfoContextMenuModule {}
